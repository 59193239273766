"use client";

import { API_URL } from "@/app/config";
import { getCookie } from "cookies-next";
import { useCallback, useEffect, useState } from "react";
import { ReqError } from "../helpers/ReqError";
import useAuthStore from "@/store";

const useLoadMe = ({
  rerouteToLoginOnFail = false,
  showToastError = false,
}) => {
  const { me, setMe, setAuthToken } = useAuthStore();
  const authToken = getCookie("auth");

  const queryMe = useCallback(async () => {
    if (Object.keys(me).length) return;

    if (!authToken) {
      if (rerouteToLoginOnFail) window.location.replace("/login");
      return;
    }

    setAuthToken(authToken);

    const headers = { Authorization: authToken };
    const response = await fetch(`${API_URL}/me`, { headers });
    const value = await response.json();

    setMe(value);

    if (!response.ok) {
      new ReqError({
        response,
        title: "Failed to get user data",
        data: value,
        rerouteToLogin: rerouteToLoginOnFail,
        showToastError,
      });
    }
  }, [
    authToken,
    me,
    rerouteToLoginOnFail,
    setAuthToken,
    setMe,
    showToastError,
  ]);

  useEffect(() => {
    queryMe();
  }, [queryMe]);

  return me;
};

export default useLoadMe;
