import { usePathname } from "next/navigation";

const paths = [
  "/pricing",
  "/models/new",
  "/models/:modelId",
  "/models/:modelId/upload",
  "/oauth_logging_in",
];

const useShouldHideNavigation = () => {
  const pathname = usePathname();
  const isModelRoute = /^\/models(\/new|\/[^/]+(\/upload)?)$/.test(pathname);

  const response = paths.some((path) =>
    path === "/models/:modelId" ? isModelRoute : pathname.includes(path)
  );

  return response;
};

export default useShouldHideNavigation;
